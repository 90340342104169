// extracted by mini-css-extract-plugin
export var rcFullCard = "rcCardFull-module--rcFullCard--2v64l";
export var lightBorder = "rcCardFull-module--lightBorder--2VCYK";
export var description = "rcCardFull-module--description--1msdh";
export var caption = "rcCardFull-module--caption--oW5Xm";
export var subCaption = "rcCardFull-module--subCaption--2qGeS";
export var contentWrapper = "rcCardFull-module--contentWrapper--3v0Pv";
export var content = "rcCardFull-module--content--3qGui";
export var contentLink = "rcCardFull-module--contentLink--21qdR";
export var img = "rcCardFull-module--img--1xjKd";
export var imgContain = "rcCardFull-module--imgContain--2-cZb";