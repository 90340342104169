import React from 'react';
import * as styles from './rcCardFull.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";

import ChevronLink from "../../helper-components/chevron-link/chevron-link";
import Link from "gatsby-link";

import StarSVG from "../../icons/fontawesome/light/star.svg";

const RcCardFull = ( {
                       premium = false,
                       to = '/',
                       actionText = 'Details',
                       fluid,
                       subCaption,
                       caption,
                       children,
                       className = '',
                       containImage = false
                    } ) => {

  return (
    <div className={className + " row no-gutters align-items-stretch " + styles.rcFullCard}>

        <div className="col-lg-4 bg-black col-12">
          <Link to={to} className={styles.imgLink} >
            <GatsbyImage image={fluid}
                         className={styles.img + " " + (containImage ? styles.imgContain : "")} />
          </Link>
        </div>

        <div className="col-lg-8 col-12 bg-white " >
          <div className={"px-lg-5 px-3 h-100 " + styles.contentWrapper + " " + styles.lightBorder}>

            <div className={styles.content}>

              <div className="d-flex align-items-center justify-content-between">

                <h4 className={"mt-lg-5 mt-4 mb-1 " + styles.caption}>
                  {caption}
                </h4>

                {premium &&
                <StarSVG/>
                }

              </div>


              {subCaption && typeof subCaption === "string" && <h5 className={"my-1"}>{subCaption}</h5>}
              {subCaption && typeof  subCaption !== "string" && subCaption}

              <div className={styles.description + " pb-5 pb-lg-6"}
                 dangerouslySetInnerHTML={
                   {
                     __html: children
                   }
                 } />
              </div>

              <Link to={to} className={styles.contentLink + " mb-4"}>
                <ChevronLink text={actionText} />
              </Link>
          </div>
        </div>

    </div>
  );

};

export default RcCardFull;
