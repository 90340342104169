import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../page-components/layout"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"
import AboExamples from "../pages-parts/abo/abo-examples"

const AboThankyouPage = ( { data } ) => {

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Vielen Dank zum Abo Abschluss</h1>
    </RcDefaultPageTeaser>

    <div className="container bg-white h-100">
      <div className="row">
        <div className="col-12 pt-3">

          <p>Mit dem Kauf des Abo haben Sie Zugriff auf alle Premium Artikel von Reischauer Consulting</p>
          <p>
            Ihr Abo und Profil können Sie unter den <Link to={"/profil"}>Profil-Einstellungen</Link> jederzeit durchführen.<br />
            Bei sonstigen Fragen und Anliegen steht Ihnen das Team von Reischauer Consulting jederzeit unter <a href={"mailto:office@reischauer.at"}>office@reischauer.at</a> zur Verfügung.
          </p>

          <h4 className={"mt-5"}>Interessante Premium Artikel</h4>

        </div>
      </div>

      <AboExamples data={data} />

    </div>
  </Layout>

};

export default AboThankyouPage;

export const query = graphql`
query AboThankYouPage {
  allWpWissen(filter: {acf_elements: {premium: {eq: true}}}) {
    nodes {
      id
      acf_elements {
        header {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        description
        autor
        premium
        
      }
      title
      slug
      date(formatString: "DD.MM.YYYY")
    }
  }
}`;
