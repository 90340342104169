import React, { useEffect, useState } from "react"
import RcCardFull from "../../components/card/rcCardFull"
import * as styles from "../../pages/wissen.module.scss"
import CalendarSVG from "../../icons/fontawesome/light/calendar-alt.svg"
import FeatherAltSVG from "../../icons/fontawesome/light/feather-alt.svg"
import StarSVG from "../../icons/fontawesome/light/star.svg"
import { useAuthContext } from "../../provider/authProvider"

const AboExamples = ( { data } ) => {

  const authContext = useAuthContext();
  const [ activated, setActivated ] = useState(false);

  useEffect(
    () => {
      if (authContext.currentUser && !activated) {
        setActivated(true);
        authContext.activateAbo();
      }
    },
    [
      authContext.currentUser
    ]
  );

  return <div className="row pb-5">
    {
      data.allWpWissen.nodes.map(
        n => <RcCardFull className={"col-12 mt-2"}
                         to={"/wissen/" + n.slug}
                         actionText={"Mehr lesen ..."}
                         children={n.acf_elements.description}
                         caption={n.title}
                         premium={n.acf_elements.premium}
                         subCaption={
                           <div className={styles.subcaption + " mb-4"}>
                             <div className="d-flex align-items-center">
                               <CalendarSVG className={"mr-1 svgFill"} /> {n.date}
                             </div>

                             { n.acf_elements.autor && <div className={"d-flex align-items-center"}>
                               <FeatherAltSVG className={"mr-1 svgFill"} /> {n.acf_elements.autor}
                             </div>}

                             { n.acf_elements.premium && <div className={"d-flex align-items-center"}>
                               <StarSVG className={"mr-1 svgFill"} /> Premium-Artikel
                             </div>}
                           </div>
                         }
                         fluid={n.acf_elements.header.localFile.childImageSharp.gatsbyImageData} />
      )}
  </div>;

};

export default AboExamples;
